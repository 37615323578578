import React, { useState } from 'react';
import styles from './email-list-form.module.css';

function EmailListForm() {

  return (
    <div class="ml-form-embed"
      data-account="1629538:k8n3g9j9x8"
      data-form="1447292:a4q8c2">
    </div>

  );
};

export default EmailListForm;